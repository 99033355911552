<!-- -->
<template>
  <div>
    <img class="wd" src="../../image/yaoqing@2x (1).png" alt="" />
    <div class="wef_whh" v-show="isShowIn == true">
      <div class="wf_wgg">
        <div class="wf_f">
          <van-field v-model="tel" type="tel" placeholder="请输入手机号码" />
        </div>
        <div class="wf_f">
          <van-field
            v-model="number"
            type="number"
            placeholder="请输入验证码"
          />
          <div @click.prevent="getCode()" class="code-btn" :disabled="!show">
            <span class="wef_huoqu" v-show="show">获取验证码</span>
            <span v-show="!show" class="count">{{ count }} s</span>
          </div>
        </div>
      </div>
      <div class="liji" @click="onLijizuc">立即注册</div>
    </div>
    <div v-show="isShowIn == false" class="weg_wg" v-html="wxHtml"></div>
    <!-- <div v-show="isShowIn == false" class="weg_wg">
      <wx-open-launch-weapp
        id="launch-btn"
        username="gh_60d3d1399dcd"
        :path="path"
      >
        <script type="text/wxtag-template">
                            <style>
                  .ant-btn {
            line-height: 1.499;
            position: relative;
            display: inline-block;
            font-weight: 400;
            white-space: nowrap;
            text-align: center;
            background-image: none;
            border: 1px solid #d9d9d9;
            -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
            cursor: pointer;
            -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            height: 46px;
            padding: 0 80px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            background-color: #fff;
            border-radius: 24px;
          }
          .ant-btn-red {
            color: #fff;
            font-size: 20px;
            border: 1px solid #fff5d1;
            background: linear-gradient(360deg, #ff6600 0%, #f69f07 100%);
            box-shadow: 0px 3px 12px rgba(254, 103, 1, 0.81);
            opacity: 1;
            border-radius: 24px;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
            -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
                            </style>
                            <button class="ant-btn ant-btn-red">进入小程序</button>
        </script>
      </wx-open-launch-weapp>
    </div> -->
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  inject: ["reload"],
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      tel: "",
      show: true,
      number: "",
      // baseUrl: "http://192.168.0.150:8000",
      baseUrl: "https://app.cheshiji.com.cn",
      count: 60,
      wxHtml: "",
      isShowIn: false,
      inviteCode: this.GetRequest("inviteCode"), //邀请码
      source: this.$route.query.source, //来源
      objId: this.$route.query.objId ? this.$route.query.objId : "",
      path: `pages/usedCar/common/login/index.html?inviteCode=${this.GetRequest(
        "inviteCode"
      )}&source=${this.$route.query.source}&objId=${
        this.$route.query.objId ? this.$route.query.objId : null
      }
`,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  created() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isShowIn = false;
      const oScript = document.createElement("script");
      oScript.type = "text/javascript";
      oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
      oScript.onload = this.getShopWxConfig;
      document.body.appendChild(oScript);
      return;
    } else {
      this.isShowIn = true;
      return;
    }
  },
  //方法集合
  methods: {
    //判断是否是微信小程序浏览器打开
    // is_weixn() {
    //   var ua = window.navigator.userAgent.toLowerCase();
    //   if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //      this.isShowIn = false;

    //     return;
    //   } else {
    //     this.isShowIn = true;
    //       this.getShopWxConfig();
    //     return;
    //     // return false;
    //   }
    // },
    onwff() {},
    onLijizuc() {
      console.log(this.path)
      if (this.tel == "") {
        this.$message({
          message: "请输入电话号码",
          type: "success",
          duration: 1500,
          onClose: () => {},
        });
        return;
      }
      if (this.number == "") {
        this.$message({
          message: "请输入验证码",
          type: "success",
          duration: 1500,
          onClose: () => {},
        });
        return;
      }
      let data = {
        inviteCode: this.inviteCode,
        mobile: this.tel,
        phoneCode: this.number,
        source: this.source,
        objId: this.objId,
      };
      this.$http
        .post(this.baseUrl + "/member/shucitycar/scqcUserInviteRecord/add", data)
        .then((e) => {
          if (e.data.code != 200) {
            this.$message({
              message: e.data.msg,
              type: "error",
              duration: 1500,
              onClose: () => {},
            });
          } else {
            Dialog.alert({
              title: "注册成功",
              message: "请到应用商城下载车世际App",
            }).then(() => {
              (this.tel = ""), (this.number = "");
            });
          }
        });
    },
    onSubmit(values) {
      console.log("submit", values);
    },
    // 获取短信验证码
    getCode() {
      if (this.tel == "") {
        this.$message({
          message: "请输入电话号码",
          type: "success",
          duration: 1500,
          onClose: () => {},
        });
        return;
      }
      //axios请求
      this.$http({
        methods: "get",
        url:
          this.baseUrl +
          "/auth/smscode?mobile=" +
          this.tel,
        data: {},
      }).then((res) => {
        if (res.data.code != 200) {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      });
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    getShopWxConfig() {
      this.$http
        .post(`${this.baseUrl}/admin/base/config/weixin/share/inject_js`, {
          url: window.location.href,
        })
        .then((res) => {
          if (res.data.code == 200) {
            let e = JSON.parse(res.data.data);
            wx.config({
              debug: false,
              appId: e.appId,
              nonceStr: e.nonceStr,
              timestamp: e.timestamp,
              signature: e.signature,
              openTagList: ["wx-open-launch-weapp"],
              jsApiList: ["chooseImage", "previewImage"],
            });
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
              this.weiLog();
              this.isShowIn = false;

              return;
            } else {
              // this.getHtml();
              this.isShowIn = true;
              // if (!this.datb) {
              //   this.isShowIn = true;
              // } else {
              //   this.isShowIn = false;
              // }

              return;
              // return false;
            }
          }
        });
    },
 
//  getShopWxConfig() {
//       this.$http
//         .post(`${this.baseUrl}/admin/base/config/weixin/share/inject_js`, {
//           url: window.location.href,
//         })
//         .then((res) => {
//           if (res.data.code == 200) {
//             let e = JSON.parse(res.data.data);
//             wx.config({
//               debug: false,
//               appId: e.appId,
//               nonceStr: e.nonceStr,
//               timestamp: e.timestamp,
//               signature: e.signature,
//               openTagList: ["wx-open-launch-weapp"],
//               jsApiList: ["chooseImage", "previewImage"],
//             });
//             wx.ready(function (res) {
//               console.log("res44444444", res);
//             });
//             wx.error(function (err) {
//               // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
//               console.log("err44444444", err);
//             });
//           }
//         });
//     },
    weiLog() {
      let content = `
      <button class="test-btn">进入小程序</button>
      <style>
        .test-btn{
          width:100%;
          border: 3px solid #FFF5D1;
          background: #FE6701;
          box-shadow: 0px 6px 24px rgba(254, 103, 1, 0.81);
          border-radius: 48px;
          color:#fff;
          height: 46px;
          line-height:46px
          border:none;
          font-size: 20px;
           margin-top: 24px;
        }
      </style>
      `;
      let script = document.createElement("script");
      script.type = "text/wxtag-template";
      script.text = content;
      this.wxHtml = `<wx-open-launch-weapp style="height: 46px;width: 328px;background: #ff8f0e;border-radius: 48px;"
		                          id="launch-btn"
		                            username="gh_60d3d1399dcd"
		                            path="pages/usedCar/common/login/index.html?inviteCode=${this.inviteCode}&source=${this.source}&objId=${this.objId}"
		                          >${script.outerHTML}
		                        </wx-open-launch-weapp>`;
      this.$nextTick(() => {
        let launchButton = document.getElementById("launch-btn");
        launchButton.addEventListener("launch", function (e) {
          console.log("success", e);
        });

        launchButton.addEventListener("error", function (e) {
          console.log("fail", e);
        });
      });
    },
    getHtml() {
      let content = `
      <button class="test-btn">注册成功跳转微信小程序</button>
      <style>
        .test-btn{
          width:100%;
          background: #ff8f0e;
          border-radius: 20px;
          color:#fff;
          height: 46px;
          line-height:46px
          font-size:16px;
          border:none;
          font-size: 16px;
           margin-top: 24px;
        }
      </style>
      `;
      let script = document.createElement("script");
      script.type = "text/wxtag-template";
      script.text = content;
      this.wxHtml = `<wx-open-launch-weapp style="height: 46px;width: 328px;background: #ff8f0e;border-radius: 24px;"
		                          id="launch-btn"
		                            username="gh_60d3d1399dcd"
		                            path="pages/homePage/index.html?inviteCode=${this.inviteCode}&source=${this.source}&objId=${this.objId}"
		                          >${script.outerHTML}
		                        </wx-open-launch-weapp>`;
      this.$nextTick(() => {
        let launchButton = document.getElementById("launch-btn");
        launchButton.addEventListener("launch", function (e) {
          console.log("success", e);
        });

        launchButton.addEventListener("error", function (e) {
          console.log("fail", e);
        });
      });
    },
    StorageFun() {
      let a = sessionStorage.getItem("invite_users");
      if (!a) {
        a = 1;
      }
      if (a < 2) {
        sessionStorage.setItem("invite_users", Number(a) + 1);
        setTimeout((e) => {
          // console.log(a)
          //  this.reload()
          window.location.reload();
        }, 200);
      } else {
        sessionStorage.setItem("invite_users", "");
      }
    },
    GetRequest(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        r[2] = r[2].replace(/%20/g, "+");
        return decodeURIComponent(r[2]);
      } else {
        return null;
      }
    },
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    // this.getShopWxConfig();
    this.StorageFun();
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */

.ant-btn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid #d9d9d9;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 46px;
  padding: 0 80px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-radius: 24px;
}
.ant-btn-red {
  color: #fff;
  font-size: 20px;
  border: 1px solid #fff5d1;
  background: linear-gradient(360deg, #ff6600 0%, #f69f07 100%);
  box-shadow: 0px 3px 12px rgba(254, 103, 1, 0.81);
  opacity: 1;
  border-radius: 24px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.weg_wg {
  display: flex;
  justify-content: center;
  position: absolute;
  align-content: center;
  width: 100%;
  margin-top: 47vh;
  bottom: 30vh;
}
.btn {
  width: 100px;
  height: 100px;
  background: chartreuse;
}
.wd {
  width: 100%;
  height: 100vh;
}
.wf_f {
  width: 328px;
  height: 42px;
  margin-top: 24px;
  position: relative;
}
.wf_wgg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.van-cell {
  border-radius: 24px;
  border: 1px solid #d5d5d5;
}
.code-btn {
  position: absolute;
  top: 0;
  right: 16px;
  line-height: 45px;
}
.wef_huoqu {
  font-size: 14px;
  font-family: Noto Sans S Chinese;
  font-weight: 500;
  line-height: 17px;
  color: #ff8f0e;
  border-left: 1px solid #d5d5d5;
  padding-left: 20px;
}
.liji {
  width: 328px;
  height: 46px;
  background: #ff8f0e;
  opacity: 1;
  border-radius: 24px;
  font-size: 16px;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  line-height: 46px;
  margin-top: 30px;
  color: #ffffff;
  text-align: center;
}
.wef_whh {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 16vh;
}
</style>